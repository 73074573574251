<template>
<div class="mainform">
  <div class="mainHeader">
      {{formData.itemName}}
      <span @click="back" class="back">返回></span>
    </div>
  <div class="form">
    <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="bidNotice"></table-tab>
    <bid-info v-if="showTab=='bidNotice'" :dataFormat="dataFormat" :formData="formData" class="content bascform"></bid-info>
    <evaluation-criteria :dataFormat="dataFormat" v-if="showTab=='scoring'" :formData="formData" class="content tableform"></evaluation-criteria>
    <div v-if="action" class="action">
      <el-button @click="reject" class="reject" type="primary" size="medium">拒绝</el-button>
      <el-button @click="accept" class="accept" type="primary" size="medium">接受</el-button>
    </div>
  </div>
      <dialog-box ref="dialogbox" dialogWidth="60%" :dialogShow="dialogShow" componentName="QuotationBody"
            @handleClose='handleClose' @cancel="handleClose" title="投标报价"
             @QuotationSubmit="QuotationSubmit" :bidEndDate="formData.bidEndDate" :quotationData="quotationData" :buttonData="buttonData">
    </dialog-box>
</div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { navData, formData, dataFormat } from './js/config.js'
export default {
  name: 'BidDetails',
  components: Component.components,
  data: function () {
    var formdata = formData
    var id = this.$route.query.id
    var status = this.$route.query.status
    let action = false
    if (id) {
      request('/api/bid/getBySupplier', 'get', { enterpriseId: sessionStorage.getItem('enterpriseCode'), bidId: id }).then((response) => {
        if (response.code === '200') {
          formdata = response.data
          this.formData = response.data
        }
      })
      if (status === null || status === '1' || status === '5') {
        action = true
      } else {
        this.action = false
      }
    }
    return {
      formData: formdata,
      navData: navData,
      showTab: 'bidNotice',
      dialogShow: false,
      action: action,
      quotationData: {},
      dataFormat: dataFormat,
      buttonData: [{ label: '取消', action: 'cancel', size: 'mini' }, { label: '确认', action: 'QuotationSubmit', size: 'mini', type: 'primary' }]
    }
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.id
        var status = this.$route.query.status
        if (status === null || status === '1' || status === '5') {
          this.action = true
        } else {
          this.action = false
        }
        if (id) {
          request('/api/bid/getBySupplier', 'get', { enterpriseId: sessionStorage.getItem('enterpriseCode'), bidId: id }).then((response) => {
            if (response.code === '200') {
              this.formData = response.data
            }
          })
        }
      }
    }
  },
  methods: {
    handleClose: function () {
      this.dialogShow = false
    },
    QuotationSubmit: function () {
      var quoteData = this.$refs.dialogbox.$refs.content.quoteData
      var tableData = this.$refs.dialogbox.$refs.content.tableData
      let pass = true
      for (const row of tableData) {
        Object.keys(row).forEach((key) => {
          if (row[key].isRequired === 1 && row[key].value === '') {
            pass = false
          }
        })
      }
      if (quoteData.paymentDays === '' || quoteData.srmFileList.length < 0) {
        pass = false
      }
      if (!pass) {
        this.$message({
          message: '请将信息填写完整！',
          type: 'error'
        })
        return
      }
      this.$confirm('是否提交报价？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quoteData.bidMaterialQuoteVoList = tableData
        quoteData.bidId = this.formData.id
        quoteData.supplierId = sessionStorage.getItem('enterpriseCode')
        request('/api/bid/supplierQuote', 'post', quoteData).then((response) => {
          if (response.code === '200') {
            this.$message({
              message: '报价成功',
              type: 'success'
            })
            this.dialogShow = false
            window.history.back()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    },
    back: () => {
      window.history.back()
    },
    onSwitch: function (data) {
      if (data.props.label === '招标信息') {
        this.showTab = 'bidNotice'
      }
      if (data.props.label === '评分标准') {
        this.showTab = 'scoring'
      }
    },
    reject: function () {
      this.$confirm('确认拒绝客户“' + this.formData.itemName + '”的投标邀请？', '拒绝', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/bid/acceptOrRjectBid', 'get', { bidId: this.formData.id, type: 2 }).then((response) => {
          if (response.code === '200') {
            this.$message({
              type: 'success',
              message: '拒绝成功!'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消拒绝'
        })
      })
    },
    accept: function () {
      request('/api/bid/quote/' + this.formData.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.quotationData = response.data
          this.dialogShow = true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
 .table_tab{
   margin-top: 20px;
 }
</style>
